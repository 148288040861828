export default function initDropdown() {
  const dropdownTriggers = document.querySelectorAll(`[data-toggle=dropdown]`);

  dropdownTriggers.forEach((trigger) => {
    let dropdown = document.querySelector(
      `[aria-labelledby=${trigger.getAttribute('id')}]`,
    );
    let close = dropdown.querySelector('.e5-dropdown-close');
    let viewWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth,
    );
    let dropdownMove = 0;
    let arrowPosition = dropdown.classList.contains('e5-dropdown-arrow-left')
      ? 'left'
      : 'right';
    let dropdownPosition =
      dropdown.classList.contains('e5-dropdown-bottom-right') ||
      dropdown.classList.contains('e5-dropdown-top-right')
        ? 'right'
        : 'left';

    // Create arrow element
    if (!dropdown.querySelector('.e5-dropdown-arrow')) {
      dropdown.insertAdjacentHTML(
        'afterbegin',
        '<div class="e5-dropdown-arrow"></div>',
      );
    }
    let dropdownArrow = dropdown.querySelector('.e5-dropdown-arrow');

    function documentClickListener(e) {
      if (!dropdown.contains(e.target)) {
        closeDropdown();
      }
    }

    function updateViewWidth() {
      viewWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth,
      );
    }

    function updateDropdownMove() {
      dropdown.style.marginLeft = null;
      let rect = dropdown.getBoundingClientRect();
      let move = 0;
      if (rect.x + rect.width > viewWidth) {
        if (window.matchMedia('(max-width: 899px)').matches) {
          move = rect.x + rect.width - viewWidth + 15;
        } else {
          move = rect.x + rect.width - viewWidth + 10 + 15;
        }

        dropdown.style.marginLeft = -1 * move + 'px';
      }
      dropdownMove = move;
    }

    function updateArrowPosition() {
      let dropdownParentWidth = dropdown.parentNode.offsetWidth;
      let dropdownWidth = dropdown.offsetWidth;
      let dropdownBorderWidth = Math.abs(
        Math.round((dropdown.clientWidth - dropdown.offsetWidth) / 2),
      );
      let arrowWidth = dropdownArrow.offsetWidth;

      // arrow position left, dropdown left
      if (arrowPosition == 'left' && dropdownPosition == 'left') {
        dropdownArrow.style.marginLeft =
          dropdownMove - dropdownBorderWidth + 'px';
      }

      // arrow position left, dropdown right
      if (arrowPosition == 'left' && dropdownPosition == 'right') {
        dropdownArrow.style.marginLeft =
          dropdownWidth -
          dropdownParentWidth +
          dropdownMove -
          dropdownBorderWidth +
          'px';
      }

      // arrow position right, dropdown left
      if (arrowPosition == 'right' && dropdownPosition == 'left') {
        dropdownArrow.style.marginLeft =
          dropdownParentWidth +
          dropdownMove -
          arrowWidth -
          dropdownBorderWidth +
          'px';
      }

      // arrow position right, dropdown right
      if (arrowPosition == 'right' && dropdownPosition == 'right') {
        dropdownArrow.style.marginLeft =
          dropdownWidth +
          dropdownMove -
          arrowWidth -
          dropdownBorderWidth +
          'px';
      }
    }

    function updatePosition() {
      updateViewWidth();
      updateDropdownMove();
      updateArrowPosition();
    }

    function openDropdown() {
      dropdown.classList.add('e5-dropdown-open');
      trigger.classList.add('e5-dropdown-open');
      trigger.setAttribute('aria-expanded', 'true');

      updatePosition();

      if (dropdown.classList.contains('e5-dropdown-filter')) {
        trigger.parentNode.classList.add('e5-dropdown-filter-open');
      }

      if (dropdown.classList.contains('e5-dropdown-modal-lg')) {
        trigger.parentNode.classList.add('e5-dropdown-modal-lg');
      }

      setTimeout(function () {
        window.addEventListener('click', documentClickListener, false);
      }, 1);

      // Scroll to dropdown on mobile
      // if (window.matchMedia('(max-width: 899px)').matches) {
      //   const yOffset = -70
      //   const y =
      //     trigger.getBoundingClientRect().top + window.pageYOffset + yOffset
      //   window.scrollTo({ top: y, behavior: 'smooth' })
      // }

      window.addEventListener('resize', updatePosition, false);
    }

    function closeDropdown() {
      dropdown.classList.remove('e5-dropdown-open');
      trigger.classList.remove('e5-dropdown-open');
      dropdown.style.marginLeft = null;
      trigger.setAttribute('aria-expanded', 'false');

      if (dropdown.classList.contains('e5-dropdown-filter')) {
        trigger.parentNode.classList.remove('e5-dropdown-filter-open');
      }

      if (dropdown.classList.contains('e5-dropdown-modal-lg')) {
        document.body.classList.remove('e5-dropdown-modal-lg');
      }

      window.removeEventListener('click', documentClickListener, false);
      window.removeEventListener('resize', updatePosition, false);
    }

    trigger.addEventListener(
      'click',
      (e) => {
        e.preventDefault();

        if (dropdown.classList.contains('e5-dropdown-open')) {
          closeDropdown();
        } else {
          openDropdown();
        }
      },
      false,
    );

    if (close) {
      close.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          closeDropdown();
        },
        false,
      );
    }
  });
}
