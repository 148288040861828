import './global.css';
import initToggles from './scripts/toggle';
import initSlideToggle from './scripts/slideToggle';
import initModal from './scripts/modal';
import initMultiSelect from './scripts/multiselect';
import initDropdown from './scripts/dropdown';
import initTabs from './scripts/tabs';
import { e3_flatpickr, e3_flatpickrMobile } from './scripts/flatpickr';
import initSelfTable from './scripts/datatables';
import { initOffersSlider } from './scripts/sliders';

initToggles();
initSlideToggle();
initModal();
initMultiSelect();
initDropdown();
initTabs();
e3_flatpickr();
e3_flatpickrMobile();
initSelfTable();
initOffersSlider();

let lastScrollY = window.scrollY;
let isScrollingUp = false;

window.addEventListener('scroll', function () {
  let scrolled = window.scrollY;
  if (scrolled > 0) {
    document.body.classList.add('scrolled');
    isScrollingUp = scrolled < lastScrollY;
    if (isScrollingUp) {
      document.body.classList.add('scrolled-up');
    } else {
      document.body.classList.remove('scrolled-up');
    }
  } else {
    document.body.classList.remove('scrolled');
    document.body.classList.remove('scrolled-up');
  }
  lastScrollY = scrolled;
});

document.addEventListener('DOMContentLoaded', () => {
  const isPresent = document.querySelector('.self-main-nav');

  if (!isPresent) {
    return;
  }

  // Assuming .self-main-nav is directly inside the scrollable parent
  // Adjust the selector if there's more specific structure
  const menu = document.querySelector('.self-main-nav');
  const menuParent = menu.parentElement;

  const activeItem = menu.querySelector('.self-main-nav-item.active');

  if (activeItem && menuParent) {
    // Calculate if the active item is in view considering the parent's scroll position
    const parentRect = menuParent.getBoundingClientRect();
    const itemRect = activeItem.getBoundingClientRect();

    // Check visibility relative to the parent
    if (itemRect.right > parentRect.right || itemRect.left < parentRect.left) {
      // Adjust scroll position of the parent to bring the active item into view
      // Calculate the scroll amount needed to bring the item into view
      const scrollLeftNeeded =
        itemRect.left - parentRect.left + menuParent.scrollLeft;
      // Smoothly scroll the parent container
      menuParent.scrollTo({ left: scrollLeftNeeded, behavior: 'smooth' });
    }
  }
});
