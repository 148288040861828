export default function initToggles() {
  const isPresent = document.querySelector('[data-toggle-element]');
  if (!isPresent) {
    return;
  }

  const toggles = document.querySelectorAll('[data-toggle-element]');
  const targets = document.querySelectorAll('[data-toggle-target]');

  function clearActives() {
    toggles.forEach((el) => {
      el.classList.remove('active');
    });
  }

  function clearWindows() {
    targets.forEach((el) => {
      el.classList.remove('is-visible');
    });
  }

  toggles.forEach((el) => {
    el.addEventListener('click', function () {
      clearActives();

      el.classList.toggle('active');

      const target = document.querySelector(
        `[data-toggle-target=${el.dataset.toggleElement}]`,
      );

      if (target.classList.contains('is-visible')) {
        clearActives();
        target.classList.remove('is-visible');
      } else {
        clearWindows();
        target.classList.add('is-visible');
      }
    });
  });

  function documentClick(e) {
    if (
      !e.target.closest('[data-toggle-element]') &&
      !e.target.closest('[data-toggle-target]')
    ) {
      clearWindows();
      clearActives();
    }
  }

  toggles.forEach((toggle) => {
    if (toggle.classList.contains('active')) {
      document.addEventListener('click', documentClick);
    } else {
      document.addEventListener('click', documentClick);
    }
  });
}
