import Choices from 'choices.js';

export default function initMultiSelect() {
  document.addEventListener('DOMContentLoaded', function () {
    var selectElements = document.querySelectorAll('.self-multiselect');

    selectElements.forEach(function (select) {
      new Choices(select, {
        removeItemButton: true, // Enable the button to remove selected items
        itemSelectText: 'Vali',
        noResultsText: 'Ühtegi tulemust ei leitud',
      });
    });
  });
}
