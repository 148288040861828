import MicroModal from 'micromodal';

export default function initModal() {
  MicroModal.init({
    disableFocus: true,
  });

  MicroModal.init({
    disableFocus: true,
    openTrigger: 'data-open-modal',
    disableScroll: true,
  });
}
