import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';

export default function initSelfTable() {
  $(document).ready(function () {
    const table = $('.self-table').DataTable({
      dom: '<ilf<t>p>',
      paging: true,
      info: true,
      searching: false,
      scrollX: true,
      order: [],
      // language: {
      //   paginate: {
      //     first: 'Esimene',
      //     last: 'Viimane',
      //     next: 'Järgmine',
      //     previous: 'Eelmine',
      //   },
      // },
    });

    const tables = document.querySelectorAll('.dataTables_wrapper');

    tables.forEach((table) => {
      const prev = table.previousElementSibling;
      const entriesCount = prev.querySelector('.self-table-info-entries-count');
      const lengthSelect = prev.querySelector(
        '.self-table-info-entries-select',
      );

      $(table).find('.dataTables_info').appendTo(entriesCount);
      $(table).find('.dataTables_length').appendTo(lengthSelect);
    });
  });
}
