import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

function initOffersSlider() {
  const swiper = new Swiper('.self-offers-slider', {
    slidesPerView: 1.1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.self-offers-slider-next',
      prevEl: '.self-offers-slider-prev',
    },
    pagination: {
      el: '.self-offers-slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      600: {
        slidesPerView: 2.2,
      },
      // when window width is >= 480px
      900: {
        slidesPerView: 3.2,
      },
      // when window width is >= 640px
      1200: {
        slidesPerView: 4,
      },
    },
  });
}

export { initOffersSlider };
