import flatpickr from 'flatpickr';

function e3_flatpickrMobile() {
  flatpickr('#period-from-to-mobile', {
    showMonths: 1,
    mode: 'range',
    // minDate: 'today',
    dateFormat: 'd.m.Y',
    inline: true,
    // locale: Estonian,
    // defaultDate: [this.state.destination.searchPeriod.fromDate!, this.state.destination.searchPeriod.toDate!]
  });
}

export { e3_flatpickrMobile };

function e3_flatpickr() {
  if (window.matchMedia('(min-width: 767px)').matches) {
    flatpickr('#period-from-to', {
      showMonths: 2,
      mode: 'range',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      inline: true,
    });
  } else {
    flatpickr('#period-from-to', {
      showMonths: 1,
      mode: 'range',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      inline: true,
    });
  }
}

export { e3_flatpickr };
